.proposal_custom_field--private_body {
  background-color: #DDD;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 1rem 0.5rem;
  margin: 1rem 0;
}

.proposal_custom_field_notice{
  &__header{
    margin-top: 1rem;
  }
  &__content{
    margin: 1rem 0;
  }
  &__list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  dd{
    font-style: italic;
  }
}